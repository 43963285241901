<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body v-if="!is.loading">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item label="Ref." :value="item.reference" />
			<app-content-summary-item label="Name" :value="item.name" :grow="1" />
			<app-content-summary-item label="Badges" :value="item.count.badges" />
			<app-content-summary-item label="Teams" :value="item.count.teams" />
			<app-content-summary-item label="Created" :value="item.date.created | localDate('Do MMMM YYYY')" alt="Unknown" />
			<app-content-summary-item label="Last active" :value="item.date.active | localDate('Do MMMM YYYY')" alt="Never" />

		</app-content-summary>

		<app-content-box title="Account" icon="user">
			
			<app-input-text label="Firstname" placeholder="Enter firstname..." v-model="model.firstname" :validation="$v.model.firstname" />
			<app-input-text label="Lastname" placeholder="Enter lastname..." v-model="model.lastname" :validation="$v.model.lastname" />
			<app-input-account label="E-mail" :original="item.email" endpoint="users/account/' + $route.params.id + '/exists" :reverse="true" type="profile" :text="emailMessages" v-model="model.email" :validation="$v.model.email" v-on:exists="onExistsChange" />
			
		</app-content-box>

		<app-content-box title="Profile" icon="profile" v-if="!isNew">

			<app-input-text label="Display name" v-model="model.displayname" :validation="$v.model.displayname" placeholder="Enter name" maxlength="32" />
			
		</app-content-box>

		<app-content-box title="Password" icon="password" v-if="!isNew">

			<p>Passwords are encrypted at time of generation and cannot be revealed or sent out again to users. Instead, when needed a new password can be generated and e-mailed to them directly. The password will also be shown to you if there is a need to manually communicate it.</p>

			<div v-if="newPassword" class="password">{{ newPassword }}<small>An e-mail has been sent to you user with their new password.</small></div>

			<app-button text="Re-generate" v-on:click="onRegenerateClick" :loading="is.regenerating" />
			
		</app-content-box>

		<app-content-box title="Badges" icon="badge" v-if="!isNew" :not-padded="true">

			<com-badges :collection="references.badges" />
			
		</app-content-box>

		<app-content-box title="Teams" icon="convention" v-if="!isNew" :not-padded="true">

			<com-teams :collection="references.teams" />
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, email } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	components: {
		'com-badges': () => import('./usersaccount/Badges'),
		'com-teams': () => import('./usersaccount/Teams')
	},

	data: function() {

		return {
			is: {
				regeneration: false
			},
			references: {
				badges: [],
				teams: []
			},
			model: {
				id: false,
				firstname: '',
				lastname: ''
			},
			emailMessages: {
				info: 'Please note if you wish to change e-mail address it cannot already be associated with another user profile.',
				exists: 'This e-mail address is already associated with another profile.',
				available: 'This e-mail is available to use.'
			},
			exists: false,
			newPassword: false
		}

	},

	validations: {
		model: {
			firstname: {
				required
			},
			lastname: {
				required
			},
			email: {
				required,
				email,
				notExist: function() {

					return !this.exists

				}
			},
			displayname: {

			}
		}
	},

	methods: {

		onExistsChange: function(e) {

			this.exists = e.exists

		},

		onRegenerateClick: function() {

			this.is.regenerating = true

			this.$api.post(['users/account', this.$route.params.id, 'regenerate']).then(function(json) {

				this.newPassword = json.password

				this.$notify({
					message: 'Password successfully re-generated.'
				})

				this.is.regenerating = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.password {
	font-size: 24px;
	font-family: courier;
	text-align: center;
	padding: 20px;
	border: 2px dashed #ccc;
	margin-bottom: 20px;
}

.password small {
	font-size: 14px;
	color: #666;
	font-family: arial;
	display: block;
	margin-top: 2px;
}

</style>